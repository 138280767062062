import { CommonModule, isPlatformBrowser } from '@angular/common';
import { CardTypes, ProductCard } from './product-card.types';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Signal, ViewChild, WritableSignal, computed, inject, input, output, signal, HostListener, Inject, afterNextRender, InputSignal, PLATFORM_ID } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { arrayToMap, isANoDeliveryDayUser, isAddressUpdateRequired, isANoPaymentMethodUser, isNoAddressUser, isSuspendedUser, sanitizeNumericInput, unableOperationMessage } from '../common/utils';
import { SignalsStoreService } from '../signals-store.service';
import { CartService } from '../cart.service';
import { LocalStorageService } from '../local-storage.service';
import { ModalContentData } from '../modal-content/modal-content';
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';
import { AddressService } from '../address.service';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../environments/environment';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PhonePipe } from '../pipes/phone.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { NotificationService } from '../notification/notification.service';
import { Session } from '../types/session.type';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../../product/products.service';
import { OrderService } from '../order.service';
import { StockService } from '../../stock/stock.service';
import { FireBaseProductOrder, BundleEditionTypes, ProductOrder, OrderResponse } from '../types/order.type';
import { SubscriptionsService } from '../../settings/account/subscriptions/subscriptions.service';
import { Subscription, SubscriptionPayload } from '../../settings/account/subscriptions/subscriptions.types';
import { combineLatest, filter, from, map, tap } from 'rxjs';
import { formatDateToReadableString, formatSubscriptionDate, getWeekNumberFromDate } from '../utils/formatting';
import { toObservable } from '@angular/core/rxjs-interop';
import { NumberRestrictionDirective } from '../directives/number-restriction.directive';
import { BundleModifyTypes } from '../types/flows-config.types';
import { Location } from '@angular/common';
import { DateTime } from 'luxon';
import { ResolutionService } from '../resolution.service';
import { AccountService } from '../account.service';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { EmptyMessageComponent } from '../empty-message/empty-message.component';
import { AvailableDeliveryDates } from '../../settings/account/deliveries/intarfaces';
import { ProductAttributeValues, PreOrderedProduct, Product } from '../../product/product.types';
import { Item } from '../types/common.types';
import { ProductCardService } from './product-card.service';
import { OutOfStockComponent } from './out-of-stock/out-of-stock.component';

@Component({
  selector: 'app-product-card',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    PhonePipe,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgbModule,
    ReactiveFormsModule,
    NumberRestrictionDirective,
    EmptyMessageComponent,
    OutOfStockComponent
  ],
  providers: [{
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.apis.google.places,
      libraries: ['places'],
      region: 'US',
    }),
  }, SubscriptionsService],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss'
})

export class ProductCardComponent implements OnInit {

  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef<HTMLInputElement>;
  private location = inject(Location)
  private notificationService = inject(NotificationService)
  localStorageService = inject(LocalStorageService)
  stockService = inject(StockService)
  signalsStoreService = inject(SignalsStoreService)
  cartService = inject(CartService)
  private modalService = inject(ModalContentService);
  private router = inject(Router)
  private productsService = inject(ProductsService);
  private orderService = inject(OrderService);
  private subscriptionsService = inject(SubscriptionsService);
  private activeModal = inject(NgbModal);
  private resolutionService = inject(ResolutionService);
  private accountService = inject(AccountService);
  deliveriesService = inject(DeliveriesService);
  #productCardService = inject(ProductCardService);

  // Mother Earth signup flow:
  @Input() public customizeBox!: boolean;
  // Farmbox signup flow:
  @Input() public subscribeAndcustomizeBox!: boolean;

  isSignupFlow = input(false, { alias: 'isSignupFlow' });
  @Output() farmBoxCard: EventEmitter<any> = new EventEmitter<any>();

  isGiftCard: InputSignal<boolean> = input(false);

  BundleEditionTypes = BundleEditionTypes;

  anonymousUserAddress: any = computed(() => this.signalsStoreService.anonymousUserAddress());
  cardType = signal(CardTypes.main);
  cardTypes = CardTypes;
  initiateSubscriptionImmediatly = signal(true);
  defaultCard: Partial<ProductCard> | any = {
    product: undefined,
    settings: {
      hasStock: false,
      showAttributes: true
    }
  }
  hasStockValidationPerQuantity: WritableSignal<boolean> = signal(false);

  isOutOfStockPerQuantity = computed(() => this.stockValidation() && this.hasStockValidationPerQuantity());
  stockValidation = computed(() => this.setUpStockForProductVariant())
  hasNotify = false;
  productAttributes: any[] = [];


  allVariantsOutOfStock = false;
  isOutOfStock: WritableSignal<boolean> = signal(false);
  #stockValidation$ = toObservable(this.stockValidation);
  #hasStockValidationPerQuantity$ = toObservable(this.hasStockValidationPerQuantity);


  totalStockAvailable = computed(() => {
    const attr = this.selectedAttribute(); // as a trigger
    const pack = this.selectedPackage();

    if (!(this.card().product?.checkStock)) return 100;

    const stockVariant = pack ? this.getVariantFromPackage() : this.getVariant();

    if (!stockVariant) return 0;
    return stockVariant.stock + (stockVariant.limit * -1);
  });

  availableQuantities = computed(() => {

    let available = this.totalStockAvailable();

    if (available > 10) available = 10;

    const quantities = []

    if ((available) <= 10) {
      for (let index = 1; index <= available; index++) {
        quantities.push({ val: index, name: (index === 10 ? '+10' : index) });
      }
    }

    return quantities;
  });

  showQuantityAlert = signal(false);

  card = input(this.defaultCard, {
    alias: 'card',
    transform: (value: any) => {
      value.product.quantity = 1;
      this.newQuantity.set(1);
      this.productId.set(value.product?.id);

      if (value.product?.packages?.length) {
        this.selectedPackage.set(value.product.packages[0]);
      }

      if (value.settings?.isSummary)
        this.cardType.set(this.cardTypes.summary);

      return {
        orderId: value?.orderId,
        product: { ...this.defaultCard?.product, ...value?.product },
        settings: { ...this.defaultCard.settings, ...value?.settings },
      }
    }
  })

  existsInOrder: Signal<{ subscription: boolean }> = computed(() => {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();

    const odooOrder = this.#getCurrentOdooOrder();
    const firebaseOrder = this.#getCurrentFirebaseOrder();

    const defaultRes = { subscription: false };

    const stockVariant = pack ? this.getVariantFromPackage() : this.getVariant();

    if (!stockVariant) return defaultRes;

    // Check if the product exists as subscription or a la carte in firebse or odoo order:
    const subscriptionFBOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variant.variantId', firebaseOrder)
    const subscriptionOdooOrderProductsMap = this.getOrderProductsMapByKey('subscription', 'variantId', odooOrder)
    // const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'variant.variantId', firebaseOrder)
    // const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'variantId', odooOrder)

    // let common = false;
    let subscription = false;
    if (stockVariant) {
      // common = oneTimeFBOrderProductsMap.has(stockVariant.variantId) || oneTimeOdooOrderProductsMap.has(stockVariant.variantId);
      subscription = subscriptionFBOrderProductsMap.has(stockVariant.variantId) || subscriptionOdooOrderProductsMap.has(stockVariant.variantId);
    }

    return { subscription }
  });


  productInCart = computed(() => this.cartService.checkProductInCart(this.card().product?.id, this.cartService.productsSignal()?.map));

  hasProductInCart = computed(() => !!this.productInCart());
  // TODO: Indentify if the user is subscribed to a product
  hasSelectedCustomQuantity: boolean = false;
  hasSession: boolean = false
  isFavorite = computed(() => this.checkFavProduct());
  selectedAttribute: WritableSignal<any> = signal('')
  selectedPackage: WritableSignal<any> = signal('')
  selectedQuantity: WritableSignal<any> = signal(1);
  selectedAttributeCardConfig = computed(() => this.setUpSelectAttributeConfig())
  selectedSize = 'option1'
  selectedDateSubscription!: any;
  selectedFrequency!: Item | undefined;
  showText: boolean = false;

  private signalsStore = inject(SignalsStoreService)
  private justAddedProduct = this.signalsStore.justAddedProduct;

  private addressService = inject(AddressService);
  private zone = inject(NgZone);
  private loader = inject(Loader);

  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading());
  hasDeliveryAddressCoverage = computed(() => !!this.addressService.navegoAddress());

  isDisabledActionButtons = computed(() => this.shouldDisableActionButtons());
  isDisabledSubscribeButton = computed(() => this.shouldDisableSubscribeActionButtons());
  marketStatus = computed(() => this.signalsStoreService.marketStatus());

  notifySelectedOption: WritableSignal<{ email?: string, phone?: string } | null> = signal(null);

  frequencies: Signal<any[]> = computed(() => {
    const freqs = this.signalsStoreService.frequencies() || []
    if (freqs.length) this.selectedFrequency = freqs[0];

    return freqs;
  });

  summaryTotalPrice = computed(() => {
    const variant = this.selectedAttributeCardConfig();
    const quantity = this.productInCart()?.quantity || this.newQuantity();
    const price = +(variant?.price || this.card().product?.price || 0);
    const totalAmount = price * quantity;
    return totalAmount;
  });

  deliveryDay: string = '';
  minDate = signal(new Date());

  cartPreview = computed(() => this.cartService.productsSignal()?.array || []);
  storedSubscriptions = computed(() => this.signalsStoreService.subscriptions() || []);
  inputValue!: number;
  wasValueHigher10!: boolean;
  newQuantity: WritableSignal<any> = signal(1);

  productId: WritableSignal<number> = signal(0);
  stockSignal = computed(() => this.stockService.mapStockSignal());
  obsStockSignal = toObservable(this.stockSignal);

  private variant: any = null;
  outSelectedVariant = output<any>({ alias: 'selectedVariant' });
  private bundle: any = null;
  private stockMapKey: any = null;

  storedDeliveryPreference: WritableSignal<any> = signal(null);
  storedAddressInfo: WritableSignal<any> = signal(null);
  storedPickUpOption: WritableSignal<any> = signal(null);


  bundleModifyTypes = BundleModifyTypes;
  bundleModifyType = environment.config.flows.bundles.modifyType;

  isMobile = computed(() => this.resolutionService.isMobile());
  isTemporaryRouteChange = signal(false);
  weekTemporaryRouteChanges: WritableSignal<number[]> = signal([]);

  deliveryDates = computed(() => structuredClone(this.deliveriesService.availableDeliveryDates()));

  $changeDeliveryDates = toObservable(this.deliveryDates);

  frmAdd: FormGroup = new FormGroup({
    deliveryDate: new FormControl<string[]>([], [Validators.required])
  });

  get deliveryDate() { return this.frmAdd.controls['deliveryDate']; }

  showInfoMessage = signal(false);
  messageAction = signal('');
  isFromWhatsInsideSubscription = signal(false);
  defaultOdooOrder = computed(() => this.#getCurrentOdooOrder());

  preOrders: WritableSignal<PreOrderedProduct[]> = signal([]);
  isPreOrderedVariant = computed(() => {
    const preOrderedProduct = this.selectedAttributeCardConfig().isPreOrdered ?? false;
    const preOrders = this.preOrders();
    if (preOrderedProduct) return true;
    if (!preOrders.length) return false;
    if (!this.validateProductAndSetUpVariant()) return false;
    const variantId = this.variant.id;
    return preOrders.some(o => o.variant.id === variantId);
  });

  isFromBlog = input(false);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    afterNextRender(() => this.#validateTemporaryRoute());
  }

  ngOnInit(): void {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (session?.deliveryInfo) {
      this.deliveryDay = session.deliveryInfo.deliveryDay;
      const [year, month_str, day] = session.deliveryInfo.deliveryDate.split('-');
      const month = (+month_str) - 1;
      const aux_date = new Date(+year, month, (+day + 1));
      this.minDate.set(new Date(aux_date));
    }

    this.obsStockSignal.subscribe(
      (res: Map<string | number, any>) => {
        if (res.size) {
          const product = this.card().product;
          if (!product) return;

          // It's a variant
          if (product.variantId) {
            if (product?.attributes?.[0])
              this.selectedAttribute.set(product.attributes[0]);
            this.productAttributes = product?.attributes ?? [];
            return
          };

          const attributes = product?.attributes;
          if (attributes) {
            this.productAttributes = attributes;
            const productHasVariants = !!attributes?.length && !!attributes?.[0].values.length;
            if (productHasVariants) {
              let values = attributes[0].values;
              if (!product.allowVariantsWithoutStock) {
                values = values.filter((value: any) => {
                  const key = `${product.id}_${attributes[0].id}_${value.id}`;
                  const stock = res.get(key);
                  if (!stock) return;

                  if (stock.stock <= 0) {
                    let oosAfterQuantityChange = (stock.stock - this.newQuantity()) < stock.limit;
                    if (stock.limit === 0 || oosAfterQuantityChange) {
                      return false;
                    }
                  }
                  return true;
                });
              }
              this.productAttributes[0].values = values;
              if (!values.length) {
                this.allVariantsOutOfStock = true;
                this.selectedAttribute.set(null);
                return;
              }
              const attr = attributes[0];
              const firstFav = values.find((v: ProductAttributeValues) => v.fav);
              const selectedAttribute = {
                id: attr.id,
                name: attr.name,
                value: firstFav ?? values[0]
              };

              this.selectedAttribute.set(selectedAttribute);

            }

            return;
          }

          const packages = product?.packages;
          if (packages?.length) {
            const key = product.id;
            const stock = res.get(key);
            if (!stock) return;

            let packages = product.packages.filter((value: any) => {
              let oosAfterQuantityChange = (stock.stock - (this.newQuantity() * value.quantity)) < stock.limit;
              if (oosAfterQuantityChange) {
                return false;
              }
              return true;
            })

            product.packages = packages;
            if (!packages.length) {
              this.allVariantsOutOfStock = true;
              this.selectedPackage.set(null);
              return;
            }

            const selectedPackage = packages[0];
            const firstDiscountPackage = packages.find((p: { isOnSale: boolean; }) => p.isOnSale);
            this.selectedPackage.set(firstDiscountPackage ?? selectedPackage);
          }


        }
      }
    )

    this.$changeDeliveryDates
      .subscribe(() => {
        if (this.cardType() === CardTypes.add)
          this.#setDeliveryDatesForDefault()
      })

    this.#setUpCarouselStock();
  }

  /*fontsize adjust for email so its doesn't break or overflow*/
  @ViewChild('emailContainer') emailContainer!: ElementRef;
  @ViewChild('emailText') emailText!: ElementRef;

  ngAfterViewInit(): void {
    this.adjustFontSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustFontSize();
  }

  adjustFontSize(): void {
    if (!isPlatformBrowser(this.platformId) || !this.emailContainer?.nativeElement || !this.emailText?.nativeElement) return;
    const containerWidth = this.emailContainer.nativeElement.offsetWidth; // Responsive width
    const emailElement = this.emailText.nativeElement;
    let fontSize = 16; // Initial font size
    emailElement.style.fontSize = `${fontSize}px`;

    // Reduce font size until the text fits within the container
    while (emailElement.scrollWidth > containerWidth && fontSize > 12) { // Set a minimum font size of 8px
      fontSize--;
      emailElement.style.fontSize = `${fontSize}px`;
    }
  }

  onKeyUp(event: any): void {
    const { value } = event?.target

    if (+ value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+value)
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputQuantityChange(event: any) {
    const { value } = event?.target
    this.wasValueHigher10 = +value >= 10;
  }

  onQuantityChange(target: any) {
    if (+target?.value >= 10)
      this.wasValueHigher10 = true;

    this.newQuantity.set(+target?.value);
    this.hasStockValidationPerQuantity.set(true);
  }

  onInputChange(event: any) {
    const newValue = +sanitizeNumericInput(event);
    this.selectedQuantity.set(newValue);
    event.target.value = newValue;
  }

  updateQuantity(productId: number, quantity: number | null) {
    if (!quantity)
      return
    this.cartService.edit(productId, { quantity })
    if (quantity < 10)
      this.wasValueHigher10 = false;
  }

  onAttributeChange(attrId: number, target: any) {
    const valueId = target.value
    let attributeSelected: any;
    for (const attribute of this.card()?.product?.attributes as any) {
      if (!attributeSelected && attribute.id === attrId) {
        const value = attribute.values?.find((value: any) => value.id === valueId);
        if (value) value.hasDiscount = !!value.originalPrice;
        attributeSelected = {
          id: attribute.id,
          name: attribute.name,
          value
        }
        break;
      }
    }

    this.selectedAttribute.set(attributeSelected);
  }

  onPackageChanged(value: string | number) {
    let packageSelected: any;
    packageSelected = this.card()?.product?.packages?.find((pkg: any) => pkg.id === value)


    this.selectedPackage.set(packageSelected);
  }

  onDateChange(event: any) {
    const selectedDate: Date = event.value;
    const formattedDate: string = formatSubscriptionDate(selectedDate);

    this.selectedDateSubscription = formattedDate;
  }

  onChangeFrequency(target: any) {
    const frequencyId = target.value
    this.selectedFrequency = this.frequencies().find(frequency => frequency.id === frequencyId);
  }

  handleFavoriteChange(event: any) {

    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stockService.mapStockSignal()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.card().product?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const variant = this.card().product?.packages?.length ? this.getVariantFromPackage() : this.getVariant();
    if (!variant)
      return unableOperationMessage(this.modalService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.modalService);

    if (event.target.checked)
      this.productsService.setFavorite(variant.id).subscribe();
    else
      this.productsService.removeFavorite(variant.id).subscribe();

    if (!this.selectedAttribute() || !this.selectedAttribute().value)
      return;

    this.selectedAttribute.update((current) => {
      current.value.fav = event.target.checked;
      return current
    });
  }

  getVariant() {

    let stock = this.stockService.mapStockSignal();

    if (!stock?.size) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    if (variant) {
      variant.id = variant.variantId;
    }
    return variant ?? null;
  }

  getVariantFromPackage() {
    let stock = this.stockService.mapStockSignal();

    if (!stock.size) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    if (variant) {
      variant.id = variant.variantId;
      variant.attribute = this.selectedAttribute() || null
    }
    return variant ?? null;
  }

  addProductToCart(isSubscription: boolean = false, event: any) {
    // If the user has not delivery day:
    if (isANoDeliveryDayUser(this.localStorageService, this.modalService)) return;
    // If the user has not payment method (abandoned user):
    if (isANoPaymentMethodUser(this.localStorageService, this.modalService, this.activeModal)) return;
    // If the should update their address:
    if (isAddressUpdateRequired(this.localStorageService, this.modalService, this.activeModal, this.router)) return;
    // If the user does not has address:
    if (isNoAddressUser(this.localStorageService, this.modalService, this.activeModal, this.router)) return;

    // If the product is available for Pre-Order:
    if (this.card().product?.preOrder) {
      this.#openModalPreOrderProduct();
      return;
    };
    // If the user has their order marked as skipped, prevent the full process:
    if (isSubscription && this.hasSkippedOrder()) return;

    // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
    // if (this.isCustomBundleModificationType(isSubscription)) return;

    // If it's a subscription it's not necessary to validate and get variant and bundle
    // because it was already validated and configured at flipCardType method:
    if (!isSubscription && !this.validateProductAndSetUpVariant()) return;

    this.messageAction.set('Product item subscribed');

    /*
    If the user did not want to start the subscription immediatly, we should to save the subscription directly.
    This subscription shouldn't be added to the  cart or the order:
    */
    if (this.selectedFrequency && !this.initiateSubscriptionImmediatly()) {
      // Validate if the user doesn't has this product as subscription pending to submit in the firebase order
      if (this.existsProductAsPendingSubscription()) return;
      const subscription = this.setUpSubscriptionPayload(this.variant);
      if (!subscription) return this.notificationService.show({ text: 'You should select a valid frequency and start date for the subscription.', type: 'error' });
      this.subscriptionsService.create(subscription).pipe(
        tap(() => {
          this.showMessageAsModal(
            'Subscription created successfully',
            'Your subscription has been saved! You can manage it anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
          );
        })
      ).subscribe();
    } else {
      const showSuccessMessage = !!this.card().orderId;
      const productToAdd = this.setUpProductToAdd(isSubscription);

      if (isSubscription)
        this.orderService.addProductToFirebaseOrder(productToAdd, showSuccessMessage, false, this.#getDefaultDeliveryDate());
      else {

        this.messageAction.set('Product item added');

        from(this.deliveryDates())
          .pipe(
            filter(x => x.isSelected && !x.isSkipped && !x.isAdded),
            tap(x => this.orderService.addProductToFirebaseOrder(productToAdd, showSuccessMessage, false, x.originalDate))
          )
          .subscribe();

        const deliveryDatesSelected = this.deliveryDates()
          .filter(x => x.isSelected)
          .map(x => x.originalDate);

        this.localStorageService.set(LOCALSTORAGE_KEYS.DELIVERY_DATES_SELECTED, deliveryDatesSelected);
      }

      if (this.isMobile())
        this.justAddedProduct.set(productToAdd);
      else {
        this.showInfoMessage.set(true);
        setTimeout(() => this.showInfoMessage.set(false), 2000);
      }

      event.stopPropagation();
    }

    this.flipCardType(this.card().settings?.isSummary || false ? CardTypes.summary : CardTypes.main);
    this.selectedFrequency = undefined;
  }

  #getDefaultDeliveryDate() {

    const currentStoredSession: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;

    if (!currentStoredSession)
      return '';

    return currentStoredSession.deliveryInfo?.deliveryDate ?? '';
  }

  private isCustomBundleModificationType(isSubscription: boolean) {
    if (this.card().product?.bundle?.items?.length && environment.config.flows.bundles.modifyType === BundleModifyTypes.outOfOrder) {
      if (isSubscription && this.existsInOrder().subscription) {

        if (!this.isFromBlog()) {
          this.router.navigate([`/shop/custom-box/${isSubscription ? BundleEditionTypes.subscription : BundleEditionTypes.aLaCarte}/${this.card().product.id}`], {
            queryParams: {
              deliveryDate: this.deliveriesService.defaultDeliveryDate()
            }
          });
        }

        return true;
      }
    }
    return false;
  }

  validateProductAndSetUpVariant(): boolean {
    // If the user is suspended, we should prevent for this action:
    if (isSuspendedUser(this.modalService, this.localStorageService)) return false;
    // If there is not stock yet, we should prevent for this action:
    if (!this.stockService.mapStockSignal()?.size) return false;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    this.bundle = this.card().product?.bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    const hasPackages: boolean = !!this.card().product?.packages?.length;
    this.variant = hasPackages ? this.getVariantFromPackage() : this.getVariant();
    if (!this.variant) {
      unableOperationMessage(this.modalService);

      return false
    }

    if (!this.bundle && !this.variant?.stock) {
      unableOperationMessage(this.modalService);
      return false
    }

    return true;
  }

  private setUpSubscriptionPayload(variant: any): SubscriptionPayload | null {
    if (!this.selectedFrequency?.id || !this.selectedDateSubscription) return null
    return {
      frequencyId: this.selectedFrequency.id,
      packageId: this.selectedPackage()?.id || null,
      startDate: this.selectedDateSubscription,
      quantity: this.newQuantity(),
      variantId: variant.id
    };
  }

  private setUpProductToAdd(isSubscription: boolean) {
    return {
      updatedAt: DateTime.now().toMillis(),
      bundle: this.bundle,
      category: this.card().product?.category,
      id: this.card().product?.id,
      img: this.card().product?.img,
      isASubscription: isSubscription,
      isFromCard: true,
      name: this.card().product?.name,
      package: this.selectedPackage() || null,
      presentation: this.card().product?.presentation || null,
      price: this.selectedAttributeCardConfig().price,
      productUrl: this.card().product?.productUrl,
      quantity: this.newQuantity(),
      size: this.selectedSize,
      subCategory: this.card().product?.subcategory as any,
      subscription: !this.selectedFrequency ? null : {
        startDate: this.selectedDateSubscription || null,
        frequency: this.selectedFrequency
      },
      taxes: this.card().product?.taxes,
      totalPrice: this.newQuantity() * (this.card().product?.price as number),
      variant: this.variant,
      isPublishedBundle: this.card().product?.isPublishedBundle ?? false
    } as Partial<FireBaseProductOrder>
  }

  openModalWhatsInside(product: Partial<Product> | undefined, event: any) {
    event.stopPropagation();
    if (!product?.bundle) return;
    const startDate = formatDateToReadableString(product.bundle.startDate).mmddyyyyFormat;
    const endDate = formatDateToReadableString(product.bundle.endDate).mmddyyyyFormat;
    this.modalService.openModal(ModalContentTypes.BOX_DETAIL, {
      title: product.name,
      fullScreenOnMobile: true,
      boxDetail: {
        isSubscription: this.card().product?.isSubscription,
        existsInOrder: this.card().product?.isSubscription ? this.existsInOrder().subscription : false,
        startDate,
        endDate,
        publishedBundles: product?.bundle?.publishedBundles,
        detail: this.card().product?.bundle?.items?.map((e: any) => {
          return {
            img: e.img,
            productName: e.name,
            quantity: e.quantity,
            packageName: e.packageName || e.display || '',
            producer: e.producerName || '',
            price: e.price,
          }
        })
      }
    } as ModalContentData).closed.subscribe(
      (res: { addToCart: boolean }) => {
        if (res.addToCart) {
          if (this.card().product?.isSubscription) {
            if (environment.config.flows.bundles.modifyType === BundleModifyTypes.default) {
              this.flipCardType(this.cardTypes.subscribe)
            } else {
              this.flipCardType(this.cardTypes.subscribe);
            }
          } else {
            this.flipCardType(this.cardTypes.add, false, true);
          }
        }
      }
    )
  }

  redirectToSignup() {
    this.router.navigate(['/signup']);
  }

  flipCardType(cardType: CardTypes, isSignupFlow: boolean = false, isSubscription = false) {

    this.isFromWhatsInsideSubscription.set(isSubscription);

    switch (cardType) {
      case CardTypes.notify:
        this.notifySelectedOption.set(this.signalsStoreService.sessionSignal()?.accountInfo?.email ? { email: this.signalsStoreService.sessionSignal()?.accountInfo?.email } : null);
        break;
      case CardTypes.subscribe:
        // If the user has their order marked as skipped, prevent the full process:
        if (this.hasSkippedOrder()) return;

        this.validateProductAndSetUpVariant();
        if (this.existsProductAsSubscription()) cardType = CardTypes.main;

        if (isSignupFlow) {
          this.localStorageService.set(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX, this.newQuantity());
          const currentDeliveryDate = this.deliveriesService.defaultDeliveryDate();
          this.location.replaceState(`/shop/custom-box/${BundleEditionTypes.subscription}/${this.card().product?.id}?deliveryDate=${currentDeliveryDate}`);
          window.location.reload();
          return;
        }

        // If the product is a bundle and the environment configuration for bundles modify type is outOfOrder, then need to redirect to custom-box component:
        if (!isSignupFlow && this.isCustomBundleModificationType(true)) return;

        break;
      case CardTypes.main:
        this.initiateSubscriptionImmediatly.set(!this.isTemporaryRouteChange());
        this.selectedDateSubscription = undefined;
        this.selectedFrequency = undefined;
        break;
      case CardTypes.add:

        if (this.isFromWhatsInsideSubscription()) {
          this.validateProductAndSetUpVariant();
          if (this.existsProductAsSubscription()) cardType = CardTypes.main;
        }

        this.#switchToAddCard();
        break;
      default:
        break;
    }
    if (!isSignupFlow) {
      this.cardType.set(cardType);
    }
  }

  #switchToAddCard() {
    this.#resetFormAdd();
    this.#setDeliveryDatesForDefault();
    this.cardType.set(CardTypes.add);
  }

  #setDeliveryDatesForDefault() {

    const dates: string[] = this.localStorageService.get(LOCALSTORAGE_KEYS.DELIVERY_DATES_SELECTED) ?? [];

    from(this.deliveryDates())
      .pipe(
        map(x => {
          x.isAdded = this.#checkIfProductExist(x.originalDate);
          return x;
        }),
        filter(x => dates.includes(x.originalDate) && !x.isSkipped && !x.isAdded),
        map(x => {
          x.isSelected = true;
          return x;
        }),
        tap(x => this.deliveryDateSelected(x))
      )
      .subscribe();
  }

  #checkIfProductExist(date: string) {

    const pack = this.selectedPackage();

    const odooOrder = this.orderService
      .odooOrder()
      .find(x => x.deliveryInfo.deliveryDate === date);

    const firebaseOrder = this.signalsStoreService.
      firebaseOrder()
      .find(x => x.deliveryDate === date);

    const stockVariant = pack ? this.getVariantFromPackage() : this.getVariant();

    if (!stockVariant) return false;

    // Check if the product exists as subscription or a la carte in firebse or odoo order:
    const oneTimeFBOrderProductsMap = this.getOrderProductsMapByKey('common', 'variant.variantId', firebaseOrder)
    const oneTimeOdooOrderProductsMap = this.getOrderProductsMapByKey('common', 'variantId', odooOrder)

    let existInCommon = false;
    if (stockVariant)
      existInCommon = oneTimeFBOrderProductsMap.has(stockVariant.variantId) || oneTimeOdooOrderProductsMap.has(stockVariant.variantId);

    return existInCommon;
  }

  #resetFormAdd() {
    this.frmAdd.reset();
    this.deliveryDate.setValue([]);
    this.deliveryDates().forEach(x => x.isSelected = false);
  }

  notifyMe() {
    if (!this.notifySelectedOption()) return;
    this.accountService.notifyOOS(this.notifySelectedOption(), this.card().product.id).pipe(
      tap(() => this.flipCardType(CardTypes.notified))
    ).subscribe();
  }

  limitChars(name: string | undefined) {
    if (!name) return
    return name.length > 30 ? name.substring(0, 26) + '...' : name
  }

  dateFilter = (date: Date | null) => {
    if (!date) {
      return false;
    }

    if (!this.deliveryDay) return true;

    const numberWeek = getWeekNumberFromDate(date);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const index = daysOfWeek.indexOf(this.deliveryDay);

    // If the date is in the week of route temporary change, disable it.
    if (this.weekTemporaryRouteChanges() && this.weekTemporaryRouteChanges().includes(numberWeek))
      return false;

    return date.getDay() === index;
  }

  private shouldDisableActionButtons() {
    return !this.stockService.mapStockSignal()?.size ||
      (this.card().product?.attributes?.length && !this.selectedAttribute()) ||
      (this.card().product?.packages?.length && !this.selectedPackage()) ||
      (this.isOutOfStock() || this.isOutOfStockPerQuantity()) ||
      (this.isPreOrderedVariant())
  }

  private shouldDisableSubscribeActionButtons() {
    return this.isDisabledActionButtons() ||
      (this.existsInOrder().subscription && this.bundleModifyType === this.bundleModifyTypes.default)
  }

  private checkFavProduct() {
    let isFavorite = false;
    const selectedAttribute = this.selectedAttribute() || null;
    // If the product has attribute or has the variant info:
    if (this.card().product?.attributes?.length && !this.card().product?.variantId) {
      if (selectedAttribute) {
        for (const attr of this.card().product?.attributes || []) {
          const favValues = attr.values.filter((v: any) => v.fav);
          isFavorite = favValues.some((fv: any) => fv.id === selectedAttribute.value.id)
        }
      }
    } else
      isFavorite = this.selectedAttributeCardConfig().fav ?? !!this.card().product?.fav

    return isFavorite;
  }

  private existsProductAsPendingSubscription() {

    const defaultFirebaseOrder = this.#getCurrentFirebaseOrder();

    if (!defaultFirebaseOrder)
      return false;

    const products = defaultFirebaseOrder.products?.subscription || [];
    if (!products?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.card().product?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in firebase order as subscription with pendind changes
    const existsInOrder = products.some((product: any) => product.variant.id === this.variant.id);
    if (existsInOrder)
      this.showMessageAsModal('Already subscribed', 'You already have an active subscription to this product. Please delete the existing subscription to add a new one with different characteristics');
    return existsInOrder;
  }

  private existsProductAsSubscription() {
    const products = this.getAllProducts();
    if (!products?.odooOrderSubscriptions?.length || !products?.storedSubscriptions?.length) return false;

    // If the product is a bundle and the editio type is out of order:
    if (this.card().product?.bundle?.items?.length && this.bundleModifyType === BundleModifyTypes.outOfOrder) return false;

    // The product exists in order
    const existsInOrder = products.odooOrderSubscriptions.some((product: any) => product.variant.id === this.variant.id);
    if (existsInOrder) {
      const message = this.setUpProductExistsMessage(true);
      this.showMessageAsModal('Already subscribed', message);
      return true;
    }

    // The product exists in user subscriptions:
    const existsAsSubscription = products.storedSubscriptions.some((subscription: Subscription) => subscription.product.variantId === this.variant.id);
    if (existsAsSubscription) {
      const message = this.setUpProductExistsMessage(false);
      this.showMessageAsModal('Already subscribed', message);
    }

    return existsAsSubscription;
  }

  private getAllProducts() {

    const currentOdooOrder = this.#getCurrentOdooOrder();
    const odooOrderSubscriptions: ProductOrder[] = currentOdooOrder
      ? currentOdooOrder.products?.subscription ?? []
      : [];

    return {
      odooOrderSubscriptions,
      storedSubscriptions: this.storedSubscriptions() ?? []
    };
  }

  private setUpProductExistsMessage(isInOrder: boolean): string {
    const productSource = isInOrder ? 'this product in your order' : 'an active subscription to this product';
    const gotoMessage = isInOrder ? 'checkout' : 'manage subscriptions';
    const routerLink = isInOrder ? '/order' : '/settings/account/subscriptions';
    const aOpenTag = `<a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="${routerLink}">`;

    return `You already have ${productSource}. Please ${aOpenTag}go to ${gotoMessage}</a> to edit your subscription`;
  }

  private showMessageAsModal(title: string, textContent: string, buttonText: string = 'I understand') {
    this.modalService.openModal(
      ModalContentTypes.CONFIRMATION,
      {
        title,
        textContent,
        confirmButtonText: 'I understand'
      }
    )
  }

  private setUpStockForProductVariant() {
    // This is for PFW to display unavailable favorite or buy again products as out of stock:
    //#region Unavaible as OOS
    const product = this.card().product;
    if (!product) return false;
    const propertiesToCheck = ['visibleOnEcommerce', 'visibleToSale'];
    if (propertiesToCheck.some(prop => product.hasOwnProperty(prop) && product[prop] === false)) {
      this.hasNotify = product.isAbleToNotify ?? true;
      return true;
    }
    //#endregion
    if (!(this.card().product?.checkStock)) return false;
    if (this.allVariantsOutOfStock) return true;
    const stockMap = this.stockSignal();
    let quantity = this.newQuantity();
    const key = this.getKeyForStockMap();
    const pack = this.selectedPackage();
    if (pack?.quantity) quantity = quantity * pack?.quantity;
    const stock = stockMap.get(key);
    this.hasNotify = false;
    if (!stock) return false;

    if (stock.stock <= 0) {
      let oosAfterQuantityChange = (stock.stock - quantity) < stock.limit;
      if (stock.limit === 0 || oosAfterQuantityChange) {
        this.hasNotify = stock.hasNotify;
        return true
      };
    } else {
      const newStock = stock.stock - quantity;
      if (newStock < 0) {
        let oosAfterQuantityChange = newStock < stock.limit;
        if (stock.limit === 0 || oosAfterQuantityChange) {
          this.hasNotify = stock.hasNotify;
          return true;
        }
      }
    }

    return false;
  }

  private getKeyForStockMap() {
    const productId = this.card().product?.id;
    let key: any = productId;
    if (this.selectedAttribute()?.id && this.selectedAttribute()?.value?.id)
      key = `${productId}_${this.selectedAttribute().id}_${this.selectedAttribute().value.id}`;

    return key;
  }

  private setUpSelectAttributeConfig() {
    const attr = this.selectedAttribute();
    const pack = this.selectedPackage();
    const { product } = this.card();
    const teaser = this.containsText(product.description?.short || '') ? product.description?.short : '';

    let variant = {
      ...product,
      hasDiscount: !!product.originalPrice,
      teaser,
      image: product.img
    };

    if (!pack)
      if (!attr || product.variantId)
        return { ...variant, isALaCarte: variant.isALaCarte ?? true };

    const stockVariant = pack ? this.getVariantFromPackage() : this.getVariant();

    if (pack?.quantity >= 0) {
      variant.price = pack.price * pack.quantity;
      variant.originalPrice = pack.originalPrice * pack.quantity;
      variant.hasDiscount = pack.originalPrice;
      variant.isOnSale = pack.isOnSale ?? variant.isOnSale;
    }

    if (attr?.value) {
      variant = {
        variantId: stockVariant?.variantId,
        ...attr.value,
        hasDiscount: !!attr.value?.originalPrice,
        teaser
      }

    }

    this.outSelectedVariant.emit({ ...variant });
    return { ...variant, isALaCarte: variant.isALaCarte ?? true }
  }

  private getOrderProductsMapByKey(productKeyType: string, mapKey: string, sourceOrder: any,) {
    if (!sourceOrder || !sourceOrder?.products?.[productKeyType]?.length)
      return new Map()

    const orderProductsMap = arrayToMap(mapKey, sourceOrder.products[productKeyType]);

    return orderProductsMap;
  }

  private containsText(htmlString: string) {
    // Parse the HTML string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    if (!doc?.body?.textContent) return false;
    // Get the text content of the parsed document
    const textContent = doc.body.textContent.trim();

    // Check if the text content is empty
    return textContent.length > 0;
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  private hasSkippedOrder() {

    if (this.defaultOdooOrder()?.isSkipped) {

      this.modalService
        .openModal(ModalContentTypes.CONFIRMATION, {
          title: `You've skipped the week`,
          textContent: `You want to add products to your order but you skipped this week's delivery`,
          confirmButtonText: 'Unskip this week',
        }, { size: 'md' }).closed
        .subscribe((res: { confirm: boolean } | null) => {
          if (!res?.confirm) return;

          const args = {
            orderId: +(this.defaultOdooOrder()?.id || 0),
            isSkipping: false,
            donationAmount: 0,
          }

          this.orderService
            .skipOrder(args, false, this.defaultOdooOrder()?.deliveryInfo.deliveryDate as string)
            .subscribe();
        })
      return true;
    }
    return false;
  }

  openModalCheckAddress() {

    let data: ModalContentData = {
      title: 'Choose your delivery preference',
      textContent:
        `Before you start shopping, let's make sure you're located near one of our delivery routes or pick up locations`,
      closeable: false,
    };

    if (this.activeModal.hasOpenModals()) this.activeModal.dismissAll();

    this.modalService.openModal(ModalContentTypes.CHECK_ADDRESS, data, undefined);
  }

  continue() {
    this.farmBoxCard.emit(this.card().product);
  }

  #setUpCarouselStock() {
    combineLatest([this.#stockValidation$, this.#hasStockValidationPerQuantity$]).pipe(
      tap(([stockValidation, hasStockValidationPerQuantity]) => {
        if (!this.card()?.product?.id) return;
        const oos = stockValidation && !hasStockValidationPerQuantity
        this.isOutOfStock.set(oos);
        if (!oos) return;
        const currentHiddenProducts: number[] = [...this.signalsStore.hiddenCarouselProducts()];
        if (!this.card().product.variantId) return;
        currentHiddenProducts.push(this.card().product.variantId);
        this.signalsStore.hiddenCarouselProducts.set([...currentHiddenProducts]);
      })
    ).subscribe();
  }

  prevAdd(event: Event) {

    if (!this.frmAdd.valid) {
      this.frmAdd.markAllAsTouched();
      return;
    }

    this.addProductToCart(this.isFromWhatsInsideSubscription(), event);
  }

  deliveryDateSelected(date: AvailableDeliveryDates & { isSelected: boolean }) {

    if (date.isSkipped || date.isAdded)
      return;

    let { value }: { value: string[] } = this.deliveryDate;

    if (date.isSelected)
      value.push(date.originalDate);
    else {

      const index = value.findIndex(x => x === date.originalDate);

      if (index !== -1)
        value.splice(index, 1);
    }

    this.deliveryDate.setValue(value);
  }

  #getCurrentOdooOrder(): OrderResponse | undefined {

    const defaultDeliveryDate = this.deliveriesService.defaultDeliveryDate();

    return this.orderService
      .odooOrder()
      .find(x => x.deliveryInfo?.deliveryDate === defaultDeliveryDate);
  }

  #getCurrentFirebaseOrder() {

    const defaultDeliveryDate = this.deliveriesService.defaultDeliveryDate();

    return this.signalsStoreService.firebaseOrder()
      .find(x => x.deliveryDate === defaultDeliveryDate);
  }

  prevUnSkip(date: AvailableDeliveryDates) {

    if (!date.orderId)
      return;

    this.modalService
      .openModal(ModalContentTypes.CONFIRMATION, {
        title: 'Unskip Order',
        textContent: `would you like to unskip the order for <strong>${date.date}</strong>?`,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
      })
      .closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return;
        this.#unSkip(date);
      });
  }

  #unSkip(date: AvailableDeliveryDates) {

    if (!date.orderId)
      return;

    const args = {
      orderId: date.orderId,
      isSkipping: false,
      donationAmount: 0
    };

    this.orderService
      .skipOrder(args, true, date.originalDate)
      .subscribe(obs => {

      });
  }

  getButtonText(conf: { isBundle: boolean, isSubscription: boolean }) {
    const { isBundle, isSubscription } = conf;
    if (isBundle) {
      if (isSubscription)
        return this.existsInOrder().subscription ? (this.bundleModifyType === this.bundleModifyTypes.outOfOrder ? 'Customize' : 'Subscribed') : 'Subscribe';
      else
        return 'Add';
    } else {
      if (isSubscription)
        return this.existsInOrder().subscription ? 'Subscribed' : 'Subscribe';
      else {
        if (this.card().product?.preOrder)
          return this.isPreOrderedVariant() ? 'Pre Ordered' : 'Pre Order';

        return 'Add';
      }
    }
  }

  #openModalPreOrderProduct() {
    this.modalService.openModal(
      ModalContentTypes.PREORDER_PRODUCT,
      {
        title: `Confirm Your Pre-Order`,
        preOrderProduct: {
          ...this.card().product,
          _variantData: this.selectedAttributeCardConfig()
        },
      }
    ).closed.subscribe(
      (res: { sendPreOrder: boolean }) => {
        if (res?.sendPreOrder) {
          this.#savePreorderProduct()
        }
      }
    )
  }

  #savePreorderProduct() {
    let preOrderData = null;

    if (!this.validateProductAndSetUpVariant()) return;

    try {
      preOrderData = this.#productCardService.setUpProductForPreOrder({
        product: this.card().product,
        variantId: this.variant.id,
        packageId: this.selectedPackage()?.id ?? undefined,
        quantity: this.newQuantity()
      });
    } catch (error) {
      return unableOperationMessage(this.modalService);
    }

    if (!preOrderData) return unableOperationMessage(this.modalService);

    this.orderService.savePreOrderProduct(preOrderData).pipe(
      tap(() => this.#afterProductPreordered())
    ).subscribe();

    return false;
  }

  #afterProductPreordered() {
    this.orderService.getPreOrders().pipe(tap(res => this.preOrders.set(res))).subscribe();

    this.showMessageAsModal(
      'Pre-Order Saved Successfully',
      'Your pre-order has been saved! You can review your pre-orders anytime in the <a class="link-success link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover cursor-pointer" routerLink="/settings/account/subscriptions">Manage Subscriptions<a> section.'
    );
  }

  #validateTemporaryRoute() {
    const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

    if (!currentSessionInfo)
      return;

    const { temporaryChange, upcomingTemporaryChanges } = currentSessionInfo?.deliveryInfo ?? {};

    this.isTemporaryRouteChange.set(!!temporaryChange?.active);
    const weekTemporaryRouteChanges = [...(upcomingTemporaryChanges?.map(e => e.weekNumber) ?? []), temporaryChange?.weekNumber ?? 0];
    this.weekTemporaryRouteChanges.set(weekTemporaryRouteChanges);
    this.initiateSubscriptionImmediatly.set(!this.isTemporaryRouteChange());
  }

  addOrSubscribeFromFatherComponent(event: any) {

    if (this.customizeBox || !this.stockSignal().size || this.defaultOdooOrder()?.isSkipped || this.isOutOfStock() || this.isOutOfStockPerQuantity())
      return;

    // Mark as selected the default delivery date

    this.#resetFormAdd();
    this.#setDeliveryDatesForDefault();

    const index = this.deliveryDates()
      .findIndex(x => x.originalDate === this.#getDefaultDeliveryDate());

    if (index !== -1) {
      if (this.deliveryDates()[index].isAdded || this.deliveryDates()[index].isSkipped)
        return;

      this.deliveryDates()[index].isSelected = true;
    }

    if (this.selectedAttributeCardConfig().isSubscription) {

      if (this.marketStatus().isOpen && this.selectedAttributeCardConfig().isALaCarte)
        this.addProductToCart(false, event);
      else {
        if (!this.existsProductAsSubscription()) {
          this.validateProductAndSetUpVariant();
          this.addProductToCart(true, event);
        }
      }

    } else if (this.selectedAttributeCardConfig().isALaCarte)
      this.addProductToCart(false, event);
  }
}
